<template>
  <div>
    <p class="mt-4" v-if="showDeliveryDetails">
      {{ $t('components.orderAmountDetails.deliveryNet') }}: {{ formatCurrency(delivery.price) }}
    </p>
    <p class="mt-2">
      {{ $t('helpers.sum') }} ({{ $t('helpers.net') }}):
      <strong>{{ formatCurrency(netSum) }}</strong>
    </p>
    <p>
      {{ $t('components.orderAmountDetails.grossSum') }}
      <strong> {{ formatCurrency(grossSum) }}</strong>
    </p>
  </div>
</template>
<script>
import { formatCurrency } from '../mixins/formatCurrency';
import { productUnitPrice } from '../mixins/productUnitPrice';
import { specialTotalPriceComputed, cartComputed, deliveryComputed } from '../store/helper';
import { calculateGrossPrice } from '../utils/priceUtils';
import { vatMultipliers } from '../static/vatMultipliers';
import { round, sum } from 'lodash';
import { permissionRolesMixin } from '../mixins/permissionRolesMixin';

export default {
  name: 'OrderAmountDetails',
  props: {
    showDeliveryDetails: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [formatCurrency, productUnitPrice, permissionRolesMixin],
  computed: {
    ...specialTotalPriceComputed,
    ...cartComputed,
    ...deliveryComputed,
    sumOfAllItems() {
      const productsPrices = this.cart.map(
        (product) => product.quantity * this.productUnitPrice(product)
      );
      return round(sum(productsPrices), 2);
    },
    totalAmount() {
      return this.isSysAdmin && this.specialTotalPrice
        ? this.specialTotalPrice
        : this.priceWithDiscount(this.sumOfAllItems);
    },
    netSum() {
      return this.showDeliveryDetails ? this.totalAmount + this.delivery.price : this.totalAmount;
    },
    grossSum() {
      return calculateGrossPrice(this.netSum, vatMultipliers.VAT23.value);
    },
  },
};
</script>
