<template>
  <div>
    <v-row class="mt-2"
      ><v-col cols="1" sm="1" class="pr-0">{{ nr }}.</v-col
      ><v-col cols="10" sm="3" :class="!onBasicSummary ? 'd-flex' : ''">
        <v-img
          v-if="isImage"
          contain
          :src="product.image"
          class="rounded mr-4 mb-2"
          :height="onBasicSummary ? 'auto' : '120px'"
          :max-width="onBasicSummary ? '120px' : '75px'"
        ></v-img>
        <div class="font-weight-bold">
          <span v-if="!onBasicSummary">
            <span v-if="isCompatible">
              <v-icon class="mb-1" color="success-primary">mdi-check</v-icon>
            </span>
            <span v-else><v-icon class="mb-1" color="warning">mdi-alert</v-icon></span>
          </span>
          {{ getNameByLang(product) }}
        </div> </v-col
      ><v-col cols="6" sm="2"
        >{{ formatCurrency(productUnitPrice) }} / {{ $t('helpers.piecesAmount') }}</v-col
      ><v-col cols="6" sm="3" class="text-center">
        <quantity-input
          :supply="product.stock"
          ref="quantityInput"
          :inProductItem="true"
          :disabledPlus="!nextQuantityAvailable"
          :disabledMinus="!previousQuantityAvailable"
          :availableGroupPrices="availableGroupPrices"
          :isOnWizard="isOnWizard"
          :startingQuantity="parseInt(product.quantity)"
          @quantityChange="onQuantityChange"
          :quantity="parseInt(product.quantity)"
          :quantity-increment="product.incrementWhileAddingToCart"
        >
        </quantity-input>
        <span v-if="!onBasicSummary"
          >{{ $t('components.productItem.recommendedAmount') }}:
          {{ product.wizardRecommends }}</span
        ></v-col
      >
      <v-col v-if="isSysAdmin">
        <v-text-field
          min="0"
          type="number"
          :rules="validator.priceGroupRules"
          hide-details="auto"
          solo
          dense
          elevation="0"
          outlined
          @change="updateCustomTotalAmount"
          :suffix="currencyLabel()"
          v-model="specialTotalPrice"
        ></v-text-field>
      </v-col>
      <v-col cols="10" sm="2" v-else
        >{{ $t('helpers.sum') }}:
        {{
          formatCurrency(
            getTotalPriceFormatted(+productUnitPrice, parseInt(product.quantity)),
            null,
            false
          )
        }}
      </v-col>
      <v-col cols="2" sm="1">
        <v-btn text icon color="primary lighten-2" @click="handleDelete"
          ><v-icon> mdi-delete </v-icon></v-btn
        ></v-col
      >
    </v-row>
    <v-row
      ><v-col><v-divider></v-divider></v-col
    ></v-row>
    <confirm-modal
      :title="$t('components.productItem.modalOne.title')"
      :open="confirmModal"
      :agree="$t('components.productItem.modalOne.agree')"
      @accept="onAccept"
      @decline="onDecline"
      :disagree="$t('components.productItem.modalOne.disagree')"
    >
      {{ $t('components.productItem.modalOne.text') }}
    </confirm-modal>
    <confirm-modal
      :title="$t('components.productItem.modalTwo.title')"
      :open="deleteModal"
      :agree="$t('components.productItem.modalTwo.agree')"
      @accept="onAcceptDelete"
      @decline="onDeclineDelete"
      :disagree="$t('components.productItem.modalTwo.disagree')"
    >
      {{ $t('components.productItem.modalTwo.text') }}
    </confirm-modal>
    <confirm-modal
      :title="$t('components.productItem.modalThree.title')"
      :open="deleteOnSummaryModal"
      :agree="$t('components.productItem.modalThree.agree')"
      :optionalActionLabel="$t('components.productItem.modalThree.optionalLabel')"
      :showOptionalAction="true"
      @optionalAction="backToSelection"
      @accept="onAcceptDeleteFromSummary"
      @decline="onDeclineDeleteFromSummary"
      :disagree="$t('components.productItem.modalThree.disagree')"
    >
      {{ $t('components.productItem.modalThree.text') }}
    </confirm-modal>
  </div>
</template>
<script>
import QuantityInput from '../QuantityInput.vue';
import ConfirmModal from '../modals/ConfirmModal.vue';
import { checkoutModes } from '../../static/checkoutModes.js';
import {
  modeComputed,
  wizardStepComputed,
  wizardSubstepComputed,
  incompatibleStepsComputed,
  incompatibleSubStepsComputed,
  userDataComputed,
} from '../../store/helper.js';
import { groupPriceMixin } from '../../mixins/groupPriceMixin.js';
import { formatCurrency } from '../../mixins/formatCurrency';
import { getNameByLanguage } from '../../mixins/getNameByLanguage';
import { roles } from '../../static/roles';
import { validators } from '../../validators/Validators.js';
import { round } from 'lodash';
import { basketDiscountMixin } from '../../mixins/basketDiscountMixin';
import { categoriesComputedMixin } from '../../mixins/categoriesComputedMixin';

export default {
  name: 'ProductItem',
  components: { QuantityInput, ConfirmModal },
  mixins: [
    groupPriceMixin,
    formatCurrency,
    getNameByLanguage,
    basketDiscountMixin,
    categoriesComputedMixin,
  ],
  props: ['product', 'nr', 'onBasicSummary', 'isOnWizard'],
  data() {
    return {
      confirmModal: false,
      deleteModal: false,
      deleteOnSummaryModal: false,
      oldQuantity: 0,
      alreadyAgree: false,
      defaultQuantity: 0,
      quantity: 0,
      incompatibleProduct: false,
      wizardRecommends: 0,
      wizardSteps: {
        1: {
          to: { name: 'Modules' },
        },
        2: {
          to: { name: 'Inverters' },
        },
        3: {
          to: { name: 'Optimizers' },
        },
        5: {
          to: { name: 'MountingAccessories' },
        },
        6: {
          to: { name: 'ElectricalInstallationEquipment' },
        },
      },
    };
  },
  methods: {
    backToSelection() {
      const wizardStep = this.getWizardStepByCategory(this.product.category);
      const stepRoute = this.wizardSteps[wizardStep];
      this.$store.commit('addToIncompatibleSteps', wizardStep);
      this.deleteFromCart();
      this.$emit('delete', this.product);
      this.setWizardStep(wizardStep);
      this.routeToStep(stepRoute);
    },

    deleteFromCart() {
      this.$store.commit('removeFromCart', this.product._id);
      this.$store.commit('handleWizardOptionsProducts', this.product._id);
    },
    handleDelete() {
      if (this.mode === checkoutModes.WIZARD && !this.onBasicSummary) {
        return (this.deleteModal = true);
      }
      if (this.mode === checkoutModes.WIZARD && this.onBasicSummary) {
        return (this.deleteOnSummaryModal = true);
      }
      this.deleteFromCart();
      this.$emit('delete', this.product);
      if (this.onBasicSummary) {
        this.checkForDiscounts();
      }
    },
    onDeclineDeleteFromSummary() {
      this.deleteOnSummaryModal = false;
    },
    setWizardStep(step) {
      this.$store.commit('setWizardStep', step);
    },
    routeToStep(stepRoute) {
      this.$router.push(stepRoute.to);
    },
    getWizardStepByCategory(category) {
      if (category === this.categoriesObj.PHOTO_MODULES.value) return 1;
      else if (category === this.categoriesObj.INVERTERS.value) return 2;
      else if (category === this.categoriesObj.OPTIMIZERS.value) return 3;
      else if (this.electricalInstallationSubcategoriesList.some((item) => item.value === category))
        return 6;
      else return 5;
    },
    onAcceptDeleteFromSummary() {
      const wizardStep = this.getWizardStepByCategory(this.product.category);
      this.$store.commit('addToIncompatibleSteps', wizardStep);
      this.deleteFromCart();
      this.$emit('delete', this.product);
    },
    onAcceptDelete() {
      this.deleteModal = false;
      this.updateIncompatibleSteps();
      this.deleteFromCart();
    },
    onDeclineDelete() {
      this.deleteModal = false;
    },
    onDecline() {
      this.product.quantity = this.defaultQuantity;
      this.confirmModal = false;
    },
    onAccept() {
      this.confirmModal = false;
      this.alreadyAgree = true;
      this.updateIncompatibleSteps();
      this.updateProductInCart(this.quantity);
    },
    updateIncompatibleSteps() {
      this.$store.commit('addToIncompatibleAccessories', this.product);
      this.incompatibleProduct = true;
    },
    updateProductInCart(newQuantity) {
      this.product.quantity = newQuantity;
      this.$store.commit('updateQuantityInCart', this.product);
    },
    checkIfModalAppears(newQuantity, oldQuantity) {
      return (
        oldQuantity === this.wizardRecommends &&
        newQuantity < this.wizardRecommends &&
        !this.alreadyAgree &&
        this.mode === checkoutModes.WIZARD &&
        !this.onBasicSummary
      );
    },

    onQuantityChange(newQuantity) {
      this.oldQuantity = this.quantity;
      this.quantity = newQuantity;

      this.confirmModal = this.checkIfModalAppears(newQuantity, this.oldQuantity);

      if (newQuantity >= this.defaultQuantity && this.mode === checkoutModes.WIZARD) {
        this.alreadyAgree = false;
        this.$store.commit('removeFromIncompatibleAccessories', this.product);
      }
      this.updateProductInCart(newQuantity);
    },
    updateCustomTotalAmount(val) {
      this.product.specialTotalPrice = round(this.getPriceInDefaultCurrency(val), 2);
      this.$store.commit('addToCart', {
        product: this.product,
        showMessage: false,
        onlyPriceChange: true,
      });
    },
  },
  watch: {
    selectedCurrency: function () {
      this.specialTotalPrice = this.totalPrice;
      this.product.specialTotalPrice = 0;
      this.specialTotalPriceByCurrency = this.specialTotalPrice;
      this.$store.commit('addToCart', {
        product: this.product,
        showMessage: false,
        onlyPriceChange: true,
      });
    },
  },
  computed: {
    specialTotalPrice: {
      get() {
        const price = this.product.specialTotalPrice
          ? this.product.specialTotalPrice
          : this.totalPrice;
        return this.calculateByCurrency(price).toFixed(2);
      },
      set(price) {
        return price;
      },
    },
    ...wizardStepComputed,
    ...wizardSubstepComputed,
    ...incompatibleStepsComputed,
    ...incompatibleSubStepsComputed,
    ...modeComputed,
    ...userDataComputed,
    isImage() {
      return this.product.image !== '' && this.product.image;
    },
    isCompatible() {
      return this.product.wizardRecommends <= this.quantity;
    },
    isSysAdmin() {
      return this.userData.role === roles.SYSADMIN;
    },
    validator() {
      return validators;
    },
  },
  created() {
    this.defaultQuantity = this.product.quantity;
    this.wizardRecommends = this.product.wizardRecommends;
    this.quantity = this.product.quantity;
  },
};
</script>
<style lang="scss" scoped></style>
